<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
import draggable from "vuedraggable";
export default {
  name: "BoGallery",
  extends: Base,
  components: {
    draggable,
  },
  data() {
    return {
      Name: "BoGallery",
      status: {},
      webSettings: {},
      data: [],
      galCategory: {},
      dataLength: 0,
      heroImage: {},
      galleryHeroImage: "",
      category: [],
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData(() =>
      this.refreshData(() => {
        this.$set(
          this.$root.page,
          "ObjectName",
          this.replacePageTitle("Galeri", "Galeri")
        );
      })
    );
    this.moduleRedirect();
  },
  computed: {
    filterKategori: {
      get() {
        return this.filter.category;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          category: newValue
        };
        this.search();
      }
    }
  },
  methods: {
    checkMove(evt, originalEvent) {
      $("html,body").animate({scrollTop:$(originalEvent.toElement).offset().top-100}, 300)
    },
    editHeroImage(v) {
      $("#modalNewsImg").modal();
      this.galleryHeroImage = v;
    },
    submitHeroImage(e) {
      if (this.heroImage.as_val.alt_img.length > 0) {
        $("[error='heroAltImage']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        {
          type: "updateHero",
          heroImage: {
            as_val: {
              img: this.heroImage.as_val.img,
              img_mobile: this.heroImage.as_val.img_mobile,
              alt_img: this.heroImage.as_val.alt_img,
              alt_img_mobile: this.heroImage.as_val.alt_img_mobile
            },
          },
        },
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required") {
                  textError = "Input Required";
                  $("[error=" + k + "]").html(
                      "<span class='error'>" + textError + "</span>"
                  );
                }
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".kr-img-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".kr-img-info");
            setTimeout(() => {
              $("#modalNewsImg").modal("hide");
            }, 500);
            this.refreshData();
          }
        },
        "POST"
      );
    },
    endDrag() {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: this.data.data,
          type: "sort",
        },
        (err, resp) => {
          
        },
        "POST"
      );
    },
    sort() {
      this.filter.sort = this.filter.sort == "all" ? "" : "all";
      if (this.filter.sort == "all") {
        this.filter.status = "";
        this.filter.category = "";
        this.filter.search = "";
      }
      var query = this.filter;
      delete query.page;
      this.$router.push({
        name: this.Name,
        query: query,
      });
    },
    submitView() {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: this.webSettings,
          type: "updateView",
        },
        (err, resp) => {
          if (resp.error)
            return Gen.info(resp.message, resp.style, 3000, ".info");
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, ".info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
  },
  watch: {
    "$route.params"() {
      this.refreshData(() => {
        this.$set(
          this.$root.page,
          "ObjectName",
          this.replacePageTitle("Galeri", "Galeri")
        );
      });
    },
    "$route.query"() {
      if (this.$route.params.id) {
        this.$set(this.row, "status", this);
        this.$set(this.row, "search", this);
        this.$set(this.row, "page", this);
        setTimeout(() => {
          this.row.status = this.$route.query.status;
          this.row.search = this.$route.query.search;
          this.row.page = this.$route.query.page;
        }, 500);
      }
      this.refreshData();
    },
    "filter.status"() {
      this.search();
    },
    "row.ag_title"(v) {
      this.row.ag_alt_img = v;
      if (v.length > 0) {
        setTimeout(() => {
          $("[name='ag_alt_img']").valid();
        }, 100);
      }
    }
  },
};
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              Hero Image Galeri
            </h5>
          </div>
          <div class="card-body">
            <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <div class="row">
                <div class="struktur-info col-12"></div>
                <div v-if="moduleRole('Edit') && !$route.params.id" class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="hero"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image Mobile'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="heromobile"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img_mobile"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img"
                        v-model="heroImage.as_val.alt_img"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Yayasan Al Hidayah'
                        }"
                        :label="'Alt Image'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img_mobile"
                        v-model="heroImage.as_val.alt_img_mobile"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Yayasan Al Hidayah'
                        }"
                        :label="'Alt Image Mobile'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                  </div>
                </div>
                <div v-else class="col-md-8">
                  <img
                    :src="uploader(heroImage.as_val.img)"
                    :alt="heroImage.as_val.alt_img"
                  />
                </div>
                <div class="col-12 mt-3 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalNewsImg"
      v-if="moduleRole('Edit') && !$route.params.id"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ubah Hero Image</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="ti-close"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <BoField
                name="image"
                mandatory
                :label="'Hero Image'"
                class="mb-0"
              >
                <Uploader
                  name="heroImage"
                  :param="{ thumbnail: true }"
                  type="hero"
                  :deleted="false"
                  uploadType="cropping"
                  v-model="galleryHeroImage"
                ></Uploader>
              </BoField>
              <BoField
                mandatory
                name="heroAltImage"
                :label="'Alt Image'"
                v-model="heroImage.as_val.alt_img"
                :attr="{
                  type: 'text',
                  placeholder: 'e.g. Hero Image Gallery'
                }"
                required=""
              ></BoField>
              <div class="col-12 mt-3 text-right">
                <button
                  v-if="moduleRole('Edit')"
                  type="submit"
                  class="btn btn-rounded btn-loading btn-info"
                >
                  Perbarui
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="!$route.params.id">
        <div class="card mb-2">
          <div class="card-header">
            <h5 class="card-title">Galeri</h5>
          </div>
          <div class="card-body ">
            <div class="info"></div>
            <VForm @resp="submitView" class="row align-items-center">
              <div class="col-6">
                <BoField name="as_val" class="mb-0" :label="'Tampilkan berdasarkan'">
                  <radio
                    name="as_val"
                    v-model="webSettings.as_val"
                    option="P"
                    :attr="validation('as_val')"
                    >Create Date
                  </radio>
                  <radio name="as_val" v-model="webSettings.as_val" option="S"
                    >Sorting</radio
                  >
                </BoField>
              </div>
              <div class="col-6 text-right">
                <button
                  v-if="moduleRole('Edit')"
                  type="submit"
                  class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading"
                >
                  Perbarui
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-header">
            <VForm @resp="search">
              <div
                class="row align-items-center justify-content-between gutter-10"
              >
                <div class="col-md-4">
                  <div class="col-sm-12 col-6">
                    <h5 class="card-title mb-0">Daftar Gallery</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row justify-content-end">
                    <div v-if="dataLength > 0" class="col-md-3 col-lg-auto col-sm-auto">
                      <router-link class="btn btn-success" :to="{query: {sortall: true}}">
                        <i class="fas fa-sort"></i> Lihat Semua
                      </router-link>
                    </div>
                    <div v-if="dataLength > 0" class="col-md-3">
                      <select2 v-model="filterKategori">
                        <option value="">-- Pilih Kategori --</option>
                        <option
                          v-for="(c, k) in category"
                          :key="k"
                          :value="c.agc_id"
                        >
                          {{ c.agc_category }}
                        </option>
                      </select2>
                    </div>
                    <div v-if="dataLength > 0" class="col-md-4 col-sm-4">
                      <div class="form-group mb-0">
                        <div class="input-group">
                          <input
                            type="text"
                            v-model="filter.search"
                            v-on:keyup.enter="search"
                            class="form-control"
                            placeholder="Cari..."
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-info"
                              type="button"
                              @click="search()"
                            >
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="dataLength > 0"
                      class="col-md-auto col-sm-auto"
                    >
                      <router-link
                        :to="{ name: Name }"
                        class="btn btn-block btn-warning"
                        >Reset</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </VForm>
          </div>
          <div class="card-body">
            <div class="row" v-if="!$route.params.id">
              <div class="col-12">
                <draggable
                  v-if="moduleRole('Edit')"
                  v-model="data.data"
                  tag="div"
                  class="row gutter-20"
                  :move="checkMove"
                  @end="endDrag"
                >
                  <div
                    class="col-lg-3 col-md-4"
                    v-for="(v, k) in data.data"
                    :key="k"
                  >
                    <div class="item-list collection">
                      <div class="row text-center">
                        <div class="col-md-12">
                          <div class="product-img recipe-cat mb-2" style="cursor:move;">
                            <img
                              :src="uploader(v.ag_photo, '250')"
                              alt="prod"
                            />
                            <div class="bullet-cta">
                              <router-link
                                v-if="moduleRole('Edit')"
                                class="bullet_edit"
                                :to="{
                                  name: Name,
                                  params: { id: v.id },
                                }"
                                v-tooltip="'Ubah'"
                                ><i class="ti-marker-alt"></i
                              ></router-link>
                              <a
                                v-if="moduleRole('Edit')"
                                href="javascript:;"
                                class="bullet_change_status bg-warning"
                                @click="
                                  changeStatusDynamic(
                                    v,
                                    'ag_is_active',
                                    'ag_id',
                                    'AppGallery'
                                  )
                                "
                                v-tooltip="'Ubah Status'"
                                ><i class="ti-settings"></i
                              ></a>
                              <a
                                v-if="moduleRole('Delete')"
                                href="javascript:;"
                                class="bullet_delete"
                                @click="deleteItem($event, k)"
                                v-tooltip="'Hapus'"
                                ><i class="ti-trash"></i
                              ></a>
                            </div>
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-warning"
                              v-if="v.ag_is_active == 'N'"
                              >Inactive</label
                            >
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-success"
                              v-if="v.ag_is_active == 'Y'"
                              >Active</label
                            >
                            <label class="mt-2 text-white py-1 rounded px-2 mx-1 label-info">{{
                              v.agc_category
                            }}</label>
                          </div>
                          <h5
                            class="card-title mt-1"
                            v-tooltip="v.ag_title.limitChar(40).endsWith('...') ? v.ag_title : ''"
                          >
                            {{
                              v.ag_title.limitChar(40)
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div v-else class="row gutter-20">
                  <div
                    class="col-lg-3 col-md-4"
                    v-for="(v, k) in data.data"
                    :key="k"
                  >
                    <div class="item-list collection">
                      <div class="row text-center">
                        <div class="col-md-12">
                          <div class="product-img recipe-cat mb-2">
                            <img
                              :src="uploader(v.ag_photo, '250')"
                              alt="prod"
                            />
                            <div class="bullet-cta">
                              <router-link
                                v-if="moduleRole('Edit')"
                                class="bullet_edit"
                                :to="{
                                  name: Name,
                                  params: { id: v.id },
                                }"
                                v-tooltip="'Ubah'"
                                ><i class="ti-marker-alt"></i
                              ></router-link>
                              <a
                                v-if="moduleRole('Edit')"
                                href="javascript:;"
                                class="bullet_change_status bg-warning"
                                @click="
                                  changeStatusDynamic(
                                    v,
                                    'ag_is_active',
                                    'ag_id',
                                    'AppGallery'
                                  )
                                "
                                v-tooltip="'Ubah Status'"
                                ><i class="ti-settings"></i
                              ></a>
                              <a
                                v-if="moduleRole('Delete')"
                                href="javascript:;"
                                class="bullet_delete"
                                @click="deleteItem($event, k)"
                                v-tooltip="'Hapus'"
                                ><i class="ti-trash"></i
                              ></a>
                            </div>
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-danger"
                              v-if="v.ag_is_active == 'N'"
                              >Inactive</label
                            >
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-success"
                              v-if="v.ag_is_active == 'Y'"
                              >Active</label
                            >
                            <label class="mt-2 text-white py-1 rounded px-2 mx-1 label-info">{{
                              v.agc_category
                            }}</label>
                          </div>
                          <h5
                            class="card-title mt-1"
                            v-tooltip="v.ag_title.length > 40 ? v.ag_title : ''"
                          >
                            {{
                              v.ag_title.substr(0, 40) +
                                (v.ag_title.length > 40 ? "..." : "")
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="NotFound" class="text-center col-md-12 mt-3">
                  <h5 class="tc">{{ NotFound }}</h5>
                </div>
                <div
                  v-if="data.data === false"
                  class="text-center col-md-12 mt-3"
                >
                  <LoadingSpinner light></LoadingSpinner>
                </div>
              </div>
            </div>
            <Pagination
              class="justify-content-end mt-3 mb-0"
              :data="data"
              :limit="3"
              @pagination-change-page="onPaging"
            ></Pagination>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="$route.params.id">
        <div class="card">
          <VForm @resp="submitForm" method="post">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">
                    {{ (row.id ? "Ubah" : "Tambah") + " " + ObjectName }}
                  </h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="row">
                <div class="col-md-7">
                  <BoField name="ag_cat" :label="'Kategori'">
                    <select2
                      name="ag_cat"
                      v-bind="validation('ag_cat')"
                      :options="galCategory"
                      :object="['agc_id', 'agc_category']"
                      v-model="row.ag_cat"
                    >
                      <option value="">-- Pilih Kategori --</option>
                    </select2>
                  </BoField>
                  <BoField 
                    name="ag_title" 
                    v-model="row.ag_title" 
                    :label="'Judul'" 
                    :attr="{placeholder: 'e.g. Lulusan terbaik 2021'}"
                    v-bind="validation('ag_title')"
                  ></BoField>
                  <BoField name="ag_is_active">
                    <radio
                      name="ag_is_active"
                      v-model="row.ag_is_active"
                      option="Y"
                      :attr="validation('ag_is_active')"
                    >
                      Active</radio
                    >
                    <radio
                      name="ag_is_active"
                      v-model="row.ag_is_active"
                      option="N"
                      >Inactive</radio
                    >
                  </BoField>
                </div>
                <div class="col-md-5">
                  <BoField name="ag_photo" class="mb-0">
                    <Uploader
                      name="ag_photo"
                      type="gallery"
                      uploadType="cropping"
                      :deleted="false"
                      :param="{ thumbnail: true }"
                      v-model="row.ag_photo"
                    ></Uploader>
                  </BoField>
                  <BoField
                    mandatory
                    name="ag_alt_img"
                    :label="'Alt Image'"
                    v-model="row.ag_alt_img"
                    :attr="{
                      type: 'text',
                      placeholder: 'e.g. Lulusan terbaik 2021'
                    }"
                    required=""
                  ></BoField>
                </div>
                <div class="col-sm-12">
                  <div class="text-right">
                    <router-link
                      :to="{name: $route.name}"
                      class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                    >
                      Kembali
                    </router-link>
                    <button
                      type="submit"
                      class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded"
                    >
                      {{ row.id ? "Ubah" : "Tambah" }}
                      <i class="icon-arrow-right14 position-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </div>
  </div>
</template>
